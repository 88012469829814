import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/EditOutlined';
import useReactRouter from 'use-react-router';
import Select from 'react-select';
import { toast } from 'react-toastify';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { Container, GroupButtons } from './styles';

import Modal from '../../components/Modal';
import api from '../../services/api';
import Table from '../../components/Table';

export default function Courses2() {
  const [data, setData] = useState(null);

  const tableTitle = 'Cursos';

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'Id',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Nome',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: 'Descrição',
    },
    {
      id: 'marketplace_course',
      numeric: false,
      disablePadding: true,
      label: 'Mercado de trabalho',
    },
    {
      id: 'extracurricular_activities',
      numeric: false,
      disablePadding: true,
      label: 'Atividades extracurriculares',
    },
    {
      id: 'average_salary',
      numeric: false,
      disablePadding: true,
      label: 'Méda salarial',
    },
    {
      id: 'workload',
      numeric: false,
      disablePadding: true,
      label: 'Carga horária',
    },
    {
      id: 'actions',
      boolean: false,
      disablePadding: true,
      label: 'Ações',
    },
  ];

  const { history } = useReactRouter();
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [titleModal, setTitleModal] = useState('');
  const [name, setName] = useState('');
  const [categorySelected, setCategorySelected] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([
    { id: '', value: '', label: '', subcategories: [] },
  ]);

  const [categoryLoading, setCategoryLoading] = useState(true);

  const [subcategorySelected, setSubcategorySelected] = useState(null);
  const [subcategoryOptions, setSubcategoryOptions] = useState([
    { id: '', value: '', label: '' },
  ]);
  const [subcategoryIsDisabled, setSubcategoryIsDisabled] = useState(true);
  const [categoryIsDisabled, setCategoryIsDisabled] = useState(true);
  const [subcategoryEditIsDisabled, setSubcategoryEditIsDisabled] = useState(
    true
  );

  const [openModalDeleteCourse, setOpenModalDeleteCourse] = useState(false);
  const [courseId, setCourseId] = useState();

  async function loadData(subcategoryId) {
    const response = await api.get(`/courses/${subcategoryId}/subcategory`);
    setData(response.data);
  }

  function handleModal(type) {
    setOpenModal(true);
    if (type === 'category') setTitleModal('Categoria');

    if (type === 'subcategory') setTitleModal('Subcategoria');
  }

  function handleChangeCategory(value) {
    setCategorySelected(value);
    const options = value.subcategories.map(e => {
      return {
        id: e.id,
        value: e.id,
        label: e.name,
      };
    });
    setSubcategorySelected(null);
    setSubcategoryOptions(options);
    setSubcategoryIsDisabled(false);
    setCategoryIsDisabled(false);
  }

  function handleChangeSubcategory(value) {
    setSubcategorySelected(value);
    setSubcategoryEditIsDisabled(false);
    loadData(value.id);
  }

  function handleClose() {
    setOpenModal(!openModal);
  }

  function handleModalEdit(type) {
    setOpenModalEdit(true);
    if (type === 'category') setTitleModal('Categoria');

    if (type === 'subcategory') setTitleModal('Subcategoria');
  }

  function handleModalDelete(type) {
    setOpenModalDelete(true);
    if (type === 'category') setTitleModal('Categoria');

    if (type === 'subcategory') setTitleModal('Subcategoria');
  }

  function handleCloseEdit() {
    setOpenModalEdit(!openModalEdit);
  }

  function handleCloseDelete() {
    setOpenModalDelete(!openModalDelete);
  }

  async function handleSubmit(event, type) {
    event.preventDefault();
    let url;

    if (type === 'Categoria') url = 'categories';

    if (type === 'Subcategoria') url = `subcategories/${categorySelected.id}`;

    try {
      const response = await api.post(`/${url}`, { name });

      toast.success(`${titleModal} criado com sucesso`);
      setOpenModal(!openModal);
      setTitleModal('');
      setName('');

      if (type === 'Categoria') {
        const option = {
          id: response.data.id,
          value: response.data.id,
          label: response.data.name,
          subcategories: [],
        };
        setCategoryOptions([option, ...categoryOptions]);
      } else if (type === 'Subcategoria') {
        const options = {
          ...categoryOptions,
        };
        const subcategory = response.data;
        const subcategoryOption = {
          id: subcategory.id,
          value: subcategory.id,
          label: subcategory.name,
        };
        subcategory.category_id = Number(subcategory.category_id);

        Object.values(options).forEach(category => {
          if (category.id === categorySelected.id) {
            category.subcategories = [subcategory, ...category.subcategories];
          }
        });

        setCategoryOptions(options);
        setSubcategoryOptions([subcategoryOption, ...subcategoryOptions]);
      }
    } catch (error) {
      toast.error(`Erro ao criar ${titleModal}`);
    }
  }

  async function handleSubmitEdit(event, type) {
    event.preventDefault();
    let url;

    if (type === 'Categoria') url = `categories/${categorySelected.id}`;

    if (type === 'Subcategoria')
      url = `subcategories/${subcategorySelected.id}`;

    try {
      const response = await api.put(`/${url}`, { name });
      toast.success(`${titleModal} editado com sucesso`);
      setOpenModalEdit(!openModalEdit);
      setTitleModal('');
      setName('');

      if (type === 'Categoria') {
        const categoryEdited = {
          id: response.data.id,
          label: response.data.name,
        };
        const options = categoryOptions;

        Object.values(options).forEach(category => {
          if (category.id === categoryEdited.id) {
            category.label = categoryEdited.label;
          }
        });

        setCategoryOptions([...options]);
      } else if (type === 'Subcategoria') {
        const subCategoryEdited = {
          id: response.data.id,
          label: response.data.name,
        };
        const options = subcategoryOptions;

        Object.values(options).forEach(subcategory => {
          if (subcategory.id === subCategoryEdited.id) {
            subcategory.label = subCategoryEdited.label;
          }
        });

        setSubcategoryOptions([...options]);
      }
    } catch (error) {
      toast.error(`Erro ao editar ${titleModal}`);
    }
  }

  async function handleSubmitDelete(event, type) {
    event.preventDefault();
    let url;

    if (type === 'Categoria') url = `categories/${categorySelected.id}`;

    if (type === 'Subcategoria')
      url = `subcategories/${subcategorySelected.id}`;

    try {
      const response = await api.delete(`/${url}`);
      toast.success(`${titleModal} deletado com sucesso`);
      setOpenModalDelete(!openModalDelete);
      setTitleModal('');
      setName('');

      if (type === 'Categoria') {
        const categoryDeleted = {
          id: response.data.id,
        };
        const options = categoryOptions;
        const newOptions = [];

        options.forEach(category => {
          if (category.id !== categoryDeleted.id) newOptions.push(category);
        });

        setCategoryOptions(newOptions);
        setCategorySelected(null);
      } else if (type === 'Subcategoria') {
        const subcategoryDeleted = {
          id: response.data.id,
        };
        const options = subcategoryOptions;
        const newOptions = [];

        options.forEach(subcategory => {
          if (subcategory.id !== subcategoryDeleted.id)
            newOptions.push(subcategory);
        });

        setSubcategoryOptions(newOptions);
        setSubcategorySelected(null);
        setData(null);
      }
    } catch (error) {
      toast.error(`${titleModal} deletado com sucesso`);
    }
  }

  function onEditCourse(id) {
    history.push(`/coursesEdit/${id}`);
  }

  useEffect(() => {
    async function load() {
      const response = await api.get('/categories');

      const option = response.data.map(e => {
        return {
          id: e.id,
          value: e.id,
          label: e.name,
          subcategories: e.subcategories,
        };
      });
      setCategoryOptions(option);
      setCategoryLoading(false);
    }
    load();
  }, []);

  function handleCloseDeleteCourse() {
    setOpenModalDeleteCourse(!openModalDeleteCourse);
  }

  function courseModalDelete(id) {
    setCourseId(id);
    setOpenModalDeleteCourse(true);
  }

  function courseDelete(event) {
    event.preventDefault();

    const newData = [];
    data.forEach(course => {
      if (course.id === courseId) return;
      newData.push(course);
    });

    setData(newData);

    api.delete(`courses/${courseId}`);

    toast.success('Deletado com sucesso');
    handleCloseDeleteCourse();
  }

  return (
    <>
      <Container size={10}>
        <div className="content-select">
          <div className="wrapper-select">
            <label htmlFor="Category">Categoria</label>
            <Select
              name="Category"
              className="select"
              isLoading={categoryLoading}
              placeholder="Selecione uma Categoria"
              options={categoryOptions}
              value={categorySelected}
              onChange={handleChangeCategory}
            />
          </div>
          <div className="actions-buttons">
            <div className="wrapper-button">
              <IconButton
                type="button"
                onClick={() => handleModal('category')}
                variant="outlined"
                color="default"
              >
                <AddCircleIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleModalEdit('category');
                  setName(categorySelected.label);
                }}
                disabled={categoryIsDisabled}
              >
                <EditIcon />
              </IconButton>

              <IconButton
                onClick={() => {
                  handleModalDelete('category');
                  setName(categorySelected.label);
                }}
                disabled={categoryIsDisabled}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="content-select subcategory">
          <div className="wrapper-select">
            <label htmlFor="Subcategory">Subcategoria</label>
            <Select
              name="Subcategory"
              className="select"
              isDisabled={subcategoryIsDisabled}
              options={subcategoryOptions}
              value={subcategorySelected}
              onChange={handleChangeSubcategory}
            />
          </div>

          <div className="actions-buttons">
            <div className="wrapper-button">
              <IconButton
                type="button"
                onClick={() => handleModal('subcategory')}
                variant="outlined"
                color="default"
                disabled={categoryIsDisabled}
              >
                <AddCircleIcon />
              </IconButton>

              <IconButton
                onClick={() => {
                  handleModalEdit('subcategory');
                  setName(subcategorySelected.label);
                }}
                disabled={subcategoryEditIsDisabled}
              >
                <EditIcon />
              </IconButton>

              <IconButton
                onClick={() => {
                  handleModalDelete('subcategory');
                  setName(subcategorySelected.label);
                }}
                disabled={subcategoryEditIsDisabled}
              >
                <DeleteIcon />
              </IconButton>
            </div>
            <div className="button-newCourse">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {}}
                href="/coursesform"
              >
                NOVO CURSO
              </Button>
            </div>
          </div>
        </div>

        {data && (
          <Table
            rows={data}
            headCells={headCells}
            title={tableTitle}
            onDelete={e => courseModalDelete(e)}
            onEdit={e => onEditCourse(e)}
          />
        )}
      </Container>
      {/* Modal Create */}
      <Modal
        title={`Criar uma nova - ${titleModal}`}
        handleClose={handleClose}
        open={openModal}
      >
        <form onSubmit={e => handleSubmit(e, titleModal)}>
          <input
            placeholder={`Digite o nome da ${titleModal}`}
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <hr />

          <GroupButtons>
            <Button className="button-create" variant="contained" type="submit">
              Criar
            </Button>

            <Button
              className="button-cancel"
              variant="contained"
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </GroupButtons>
        </form>
      </Modal>
      {/* Modal Edit */}
      <Modal
        title={`Editar uma - ${titleModal}`}
        handleClose={handleCloseEdit}
        open={openModalEdit}
      >
        <form onSubmit={e => handleSubmitEdit(e, titleModal)}>
          <input
            placeholder={`Digite o nome da ${titleModal}`}
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <hr />

          <GroupButtons>
            <Button className="button-create" variant="contained" type="submit">
              Editar
            </Button>
            <Button
              className="button-cancel"
              variant="contained"
              onClick={handleCloseEdit}
            >
              Cancelar
            </Button>
          </GroupButtons>
        </form>
      </Modal>
      {/* Modal Delete */}
      <Modal
        title={`Deletar uma - ${titleModal}`}
        handleClose={handleCloseDelete}
        open={openModalDelete}
      >
        <form onSubmit={e => handleSubmitDelete(e, titleModal)}>
          <input
            placeholder={`Digite o nome da ${titleModal}`}
            value={name}
            onChange={e => setName(e.target.value)}
            disabled
          />
          <hr />
          <div className="content-btn">
            <GroupButtons>
              <Button variant="contained" color="secondary" type="submit">
                Editar
              </Button>
              <Button
                className="button-cancel"
                variant="contained"
                onClick={handleCloseDelete}
              >
                Cancelar
              </Button>
            </GroupButtons>
          </div>
        </form>
      </Modal>
      {/* Modal to Delete Course */}
      <Modal
        title="Deseja excluir o curso?"
        handleClose={handleCloseDeleteCourse}
        open={openModalDeleteCourse}
      >
        <form onSubmit={e => courseDelete(e)}>
          <input value={courseId} hidden />
          <div className="content-btn">
            <GroupButtons>
              <Button color="secondary" variant="contained" type="submit">
                Deletar
              </Button>
              <Button
                className="button-cancel"
                variant="contained"
                onClick={handleCloseDeleteCourse}
              >
                Cancelar
              </Button>
            </GroupButtons>
          </div>
        </form>
      </Modal>
    </>
  );
}
