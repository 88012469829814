import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Table from '../../components/Table';
import api from '../../services/api';
import Modal from '../../components/Modal';
import useReactRouter from 'use-react-router';
import { toast } from 'react-toastify';
import { Container } from './styles';

export default function Partners() {
  const { history } = useReactRouter();
  const [partnerId, setPartnerId] = useState();
  const [data, setData] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const tableTitle = 'Parceiros';

  useEffect(() => {
    async function loadData() {
      const response = await api.get('/partners');
      setData(response.data);
    }
    loadData();
  }, []);

  function handleCloseDelete() {
    setOpenModalDelete(!openModalDelete);
  }

  function partnersEdit(id) {
    history.push(`partnersEdit/${id}`);
  }

  function partnersModalDelete(partnerId) {
    setPartnerId(partnerId);
    setOpenModalDelete(true);
  }

  function partnerDelete(event) {
    event.preventDefault();

    const newData = [];
    data.forEach(partner => {
      if (partner.id === partnerId) return;
      newData.push(partner);
    });

    setData(newData);

    api.delete(`partners/${partnerId}`);

    toast.success('Deletado com sucesso');
    handleCloseDelete();
  }

  useEffect(() => {
    async function loadData() {
      const response = await api.get('/partners');
      setData(response.data);
    }
    loadData();
  }, []);

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'Id',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Nome',
    },
    {
      id: 'slug',
      numeric: false,
      disablePadding: false,
      label: 'Apelido',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'telephone',
      numeric: false,
      disablePadding: false,
      label: 'Telefone',
    },
    {
      id: 'state',
      numeric: false,
      disablePadding: false,
      label: 'Estado',
    },
    {
      id: 'city',
      numeric: false,
      disablePadding: false,
      label: 'Cidade',
    },
    {
      id: 'actions',
      boolean: false,
      disablePadding: false,
      label: 'Ações',
    },
  ];

  return (
    <>
      <Container>
        <div className="button-newPartner">
          <Button
            variant="contained"
            color="primary"
            onClick={() => { }}
            href="/partnersform"
          >
            NOVO PARCEIRO
          </Button>
        </div>

        {data && (
          <Table
            rows={data}
            headCells={headCells}
            title={tableTitle}
            onDelete={e => partnersModalDelete(e)}
            onEdit={e => partnersEdit(e)}
          />
        )}
      </Container>

      <Modal
        title={'Excluir Parceiro'}
        handleClose={handleCloseDelete}
        open={openModalDelete}
      >
        <form onSubmit={e => partnerDelete(e)}>
          <input value={partnerId} hidden />
          <div className="content-btn">
            <Button variant="contained" color="secondary" type="submit">
              Deletar
            </Button>
            <Button
              className="button-back"
              variant="contained"
              color="primary"
              href={handleCloseDelete}
              onClick={handleCloseDelete}
            >
              Cancelar
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
