import styled from 'styled-components';

export const DivInput = styled.div`
  div.divInput {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    border-top: 1px solid #e0e0e0;
    padding: 15px;

    .label-in-middle {
      display: flex;
      align-items: center;
    }
  }
`;

export const InputStyle = styled.div`
  input {
    
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    height: 36px;
    width: 649px;
    padding: 0px 10px;
  }
`;
