import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import useReactRouter from 'use-react-router';
import api from '../../services/api';
import InputFlex from '../../components/InputFlex';
import { Container } from './styles';

export default function UsersForm() {
  const { history } = useReactRouter();
  const [dataToSent, setDataToSent] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    newpassword: '',
  });

  const url = window.location.href;
  const userId = url.split('/').slice(-1);

  useEffect(() => {
    async function loadData() {
      const response = await api.get(`/users/${userId}/get`);

      setDataToSent({
        name: response.data[0].name,
        surname: response.data[0].surname,
        email: response.data[0].email,
        password: response.data[0].password,
        newpassword: response.data[0].newpassword,
      });
    }
    loadData();
  }, [userId]);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = new FormData();
    data.append('name', dataToSent.name);
    data.append('surname', dataToSent.surname);
    data.append('email', dataToSent.email);
    data.append('password', dataToSent.password);

    await api.put(`/users/${userId}`, data);
    toast.success('Sucesso');
    history.push('/users');
  }

  return (
    <Container>
      <div className="button-newUser">
        <Form
          onSubmit={handleSubmit}
          method="POST"
          encType="multipart/form-data"
        >
          <h1>Editar usuário</h1>
          <InputFlex
            type="text"
            name="name"
            value={dataToSent.name}
            label="Nome"
            className="name"
            required
            onChange={e =>
              setDataToSent({ ...dataToSent, name: e.target.value })
            }
          />

          <br />
          <InputFlex
            type="text"
            name="surname"
            value={dataToSent.surname}
            label="Sobrenome"
            className="surname"
            required
            onChange={e =>
              setDataToSent({ ...dataToSent, surname: e.target.value })
            }
          />

          <br />
          <InputFlex
            type="email"
            name="email"
            value={dataToSent.email}
            label="Email"
            className="email"
            onChange={e =>
              setDataToSent({ ...dataToSent, email: e.target.value })
            }
            required
          />

          <br />
          <InputFlex
            type="password"
            name="password"
            value={dataToSent.password}
            onChange={e =>
              setDataToSent({ ...dataToSent, password: e.target.value })
            }
            label="Senha"
            className="password"
            required
          />

          <br />
          <Button variant="contained" color="primary" type="submit">
            SALVAR ALTERAÇÕES
          </Button>

          <Button
            className="button-back"
            variant="contained"
            color="primary"
            href="../users"
          >
            VOLTAR
          </Button>
        </Form>
      </div>
    </Container>
  );
}
