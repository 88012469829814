import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;

  .menuButton {
    margin-right: 16px;
  }
  .title {
    flex-grow: 1;
  }
  header {
    box-shadow: none;
    background-color: #063057;
  }
`;
