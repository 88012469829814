import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import Select from 'react-select';
import { toast } from 'react-toastify';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import useReactRouter from 'use-react-router';
import Upload from '../../../components/Upload';
import FileList from '../../../components/FileList';
import api from '../../../services/api';
import converToSlug, { onlyNumber } from '../../../utils/convertToSlug';

import { Container } from './styles';

export default function NewUnit() {
  const useStyles = makeStyles(theme => ({
    textField: {
      height: '56px',
      width: '100%',
      background: '#fff',
      border: '1px solid #E0E0E0',
      borderRadius: '4px',
    },
    selectCategory: {
      height: '36px',
      marginBottom: '16px',
    },
    content: {
      display: 'flex',
      justifyContent: 'flex-end',
      maxWidth: '1208px',
      marginTop: '24px',
      marginBottom: '24px',
    },
    button: {
      marginRight: '20px',
    },
  }));

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 36,
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 5,
    }),
    clearIndicator: base => ({
      ...base,
      padding: 5,
    }),
    multiValue: base => ({
      ...base,
    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 6px',
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
  };
  const { history, match } = useReactRouter();
  const classes = useStyles();
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [slug, setSlug] = useState('');
  const [voucher, setVoucher] = useState('');
  const [address, setAddress] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [courses, setCourses] = useState([]);
  const [selected, setSelected] = useState([]);
  const [coursesLoading, setCoursesLoading] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState({
    structure_image: [],
    unit_image: [],
  });
  const [categorySelected, setCategorySelected] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([
    { id: '', value: '', label: '', subcategories: [] },
  ]);

  const [categoryLoading, setCategoryLoading] = useState(true);

  async function handleDeleteFileDownload(id, type) {
    await api.delete(`files/${id}`);

    setUploadedFiles(prevState => {
      const removeValue = prevState[type].filter(value => id !== value.id);
      return {
        ...prevState,
        [type]: removeValue,
      };
    });
  }

  function updateFile(id, type, data) {
    setUploadedFiles(prevState => {
      const newValue = prevState[type].map(value => {
        return id === value.id ? { ...value, ...data } : value;
      });

      return {
        ...prevState,
        [type]: newValue,
      };
    });
  }

  function processUpload(upFile, type) {
    const data = new FormData();

    data.append('file', upFile.file, upFile.name);

    api
      .post(`files?img_type=${type}`, data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total), 10);

          updateFile(upFile.id, type, {
            progress,
          });
        },
      })
      .then(response => {
        updateFile(upFile.id, type, {
          uploaded: true,
          id: response.data.id,
          url: response.data.url,
          type,
        });
      })
      .catch(response => {
        console.log('err', response);
        updateFile(upFile.id, type, {
          error: true,
        });
      });
  }

  function handleUpload(files, type) {
    const uploaded = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
      type,
    }));
    setUploadedFiles(prevState => {
      return {
        ...prevState,
        [type]: uploadedFiles[type].concat(uploaded),
      };
    });
    uploaded.forEach(e => processUpload(e, type));
  }

  async function loadCourses(categoryId) {
    setCoursesLoading(true);
    const response = await api.get(`/courses/${categoryId}/coursesByCategory`);

    setCourses(response.data);
    setCoursesLoading(false);
  }

  function handleChangeCategory(value) {
    setCategorySelected(value);
    loadCourses(value.id);
  }

  function handleClick(event, id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const { id } = match.params;
    const files = uploadedFiles.structure_image.concat(
      uploadedFiles.unit_image
    );
    const file_id = files.map(f => f.id);
    if (id) {
      try {
        await api.put(`v2/units/${id}`, {
          file_id,
          city,
          email,
          address,
          slug,
          course_id: selected,
          voucher,
          facebook,
          instagram,
          whatsapp,
        });

        toast.success('Unidade Criada com sucesso');
        history.push('/units');
      } catch (error) {
        toast.error('Não possível criar essa unidade');
      }
    } else {
      try {
        await api.post('v2/units', {
          file_id,
          city,
          email,
          address,
          slug,
          course_id: selected,
          voucher,
          facebook,
          instagram,
          whatsapp,
        });

        toast.success('Unidade Criada com sucesso');
        history.push('/units');
      } catch (error) {
        toast.error('Não possível criar essa unidade');
      }
    }
  }

  useEffect(() => {
    async function load() {
      const response = await api.get('/categories');

      const option = response.data.map(e => {
        return {
          id: e.id,
          value: e.id,
          label: e.name,
          subcategories: e.subcategories,
        };
      });
      setCategoryOptions(option);
      setCategoryLoading(false);
    }
    load();
  }, []);

  useEffect(() => {
    const { id } = match.params;

    async function loadData(dataId) {
      const response = await api.get(`v2/units/${dataId}`);

      setSlug(response.data.slug);
      setCity(response.data.city);
      setEmail(response.data.email);
      setAddress(response.data.address);
      setVoucher(response.data.voucher);
      setFacebook(response.data.facebook);
      setInstagram(response.data.instagram);
      setWhatsapp(response.data.whatsapp);

      if (response.data.files.length > 0) {
        setUploadedFiles(() => {
          const files = response.data.files.map(element => ({
            ...element,
            uploaded: true,
            preview: element.url,
          }));
          return {
            structure_image: files.filter(
              f => f.img_type === 'structure_image'
            ),
            unit_image: files.filter(f => f.img_type === 'unit_image'),
          };
        });
      }

      if (response.data.courses.length > 0) {
        setSelected(() => {
          return response.data.courses.map(c => c.id);
        });
      }
    }
    if (id) {
      loadData(id);
    }
  }, [match.params]);

  const isSelected = courseId => selected.indexOf(courseId) !== -1;

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <h1>Nova Unidade</h1>
        <hr />
        <ul>
          <li className="item">
            <div className="left-column flex box">
              <h2>Nome do Polo (Cidade)</h2>
            </div>
            <div className="center-column  box">
              <TextField
                id="outlined-bare-city"
                className={classes.textField}
                placeholder="Nome da cidade"
                label="Nome"
                margin="normal"
                variant="outlined"
                inputProps={{ 'aria-label': 'bare' }}
                rowsMax="1"
                rows="1"
                multiline
                fullWidth
                value={city}
                onChange={e => setCity(e.target.value)}
                style={{ zIndex: 0 }}
                required
              />
            </div>
          </li>

          <li className="item">
            <div className="left-column flex box">
              <h2>Email</h2>
            </div>
            <div className="center-column  box">
              <TextField
                id="outlined-bare-email"
                className={classes.textField}
                placeholder="Email"
                label="Email"
                margin="normal"
                variant="outlined"
                inputProps={{ 'aria-label': 'bare' }}
                rowsMax="1"
                rows="1"
                multiline
                fullWidth
                value={email}
                onChange={e => setEmail(e.target.value)}
                style={{ zIndex: 0 }}
                required
              />
            </div>
          </li>

          <li className="item">
            <div className="left-column flex box">
              <h2>Apelido</h2>
            </div>
            <div className="center-column box">
              <TextField
                id="outlined-bare-slug"
                className={classes.textField}
                placeholder="Apelido"
                label="Apelido"
                margin="normal"
                variant="outlined"
                inputProps={{ 'aria-label': 'bare' }}
                rowsMax="1"
                rows="1"
                multiline
                fullWidth
                value={slug}
                onChange={e => setSlug(converToSlug(e.target.value))}
                style={{ zIndex: 0 }}
                required
              />
            </div>
          </li>
          <li className="item">
            <div className="left-column flex box">
              <h2>Cupom</h2>
            </div>
            <div className="center-column box">
              <TextField
                id="outlined-bare-voucher"
                className={classes.textField}
                placeholder="Cupom"
                label="Cupom"
                margin="normal"
                variant="outlined"
                inputProps={{ 'aria-label': 'bare' }}
                rowsMax="1"
                rows="1"
                multiline
                fullWidth
                value={voucher}
                onChange={e => setVoucher(e.target.value)}
                style={{ zIndex: 0 }}
              />
            </div>
          </li>
          <li className="item">
            <div className="left-column flex box">
              <h2>Endereço</h2>
            </div>
            <div className="center-column box">
              <TextField
                id="outlined-bare-address"
                className={classes.textField}
                placeholder="Endereço"
                label="Endereço"
                margin="normal"
                variant="outlined"
                inputProps={{ 'aria-label': 'bare' }}
                rowsMax="1"
                rows="1"
                multiline
                fullWidth
                value={address}
                onChange={e => setAddress(e.target.value)}
                style={{ zIndex: 0 }}
                required
              />
            </div>
          </li>
          <li className="item">
            <div className="left-column flex box">
              <h2>Facebook</h2>
            </div>
            <div className="center-column box">
              <TextField
                id="outlined-bare-facebook"
                className={classes.textField}
                placeholder="Facebook"
                label="Facebook"
                margin="normal"
                variant="outlined"
                inputProps={{ 'aria-label': 'bare' }}
                rowsMax="1"
                rows="1"
                multiline
                fullWidth
                value={facebook}
                onChange={e => setFacebook(e.target.value)}
                style={{ zIndex: 0 }}
                required
              />
            </div>
          </li>
          <li className="item">
            <div className="left-column flex box">
              <h2>Instagram</h2>
            </div>
            <div className="center-column box">
              <TextField
                id="outlined-bare-instagram"
                className={classes.textField}
                placeholder="Instagram"
                label="Instagram"
                margin="normal"
                variant="outlined"
                inputProps={{ 'aria-label': 'bare' }}
                rowsMax="1"
                rows="1"
                multiline
                fullWidth
                value={instagram}
                onChange={e => setInstagram(e.target.value)}
                style={{ zIndex: 0 }}
                required
              />
            </div>
          </li>
          <li className="item">
            <div className="left-column flex box">
              <h2>Whatsapp</h2>
            </div>
            <div className="center-column box">
              <TextField
                id="outlined-bare-whatsapp"
                className={classes.textField}
                type="number"
                placeholder="082999999"
                label="Whatsapp"
                margin="normal"
                variant="outlined"
                inputProps={{ 'aria-label': 'bare' }}
                rowsMax="1"
                rows="1"
                multiline
                fullWidth
                value={whatsapp}
                onChange={e => setWhatsapp(onlyNumber(e.target.value))}
                style={{ zIndex: 0 }}
                required
              />
            </div>
          </li>
          <li className="item">
            <div className="left-column box">
              <h2>Uma foto da FACHADA do polo</h2>
              <p>
                Adicione uma foto da fachada do polo, de frente, com boa
                iluminação
              </p>
              <p className="bold">Apenas 1 imagem</p>
            </div>
            <div className="center-column box">
              {!!uploadedFiles.unit_image.length < 1 && (
                <Upload
                  onUpload={e => handleUpload(e, 'unit_image')}
                  message="Clique ou arraste aqui para enviar"
                  backgroundColor="download"
                  accept="image/jpeg, image/png"
                />
              )}

              {!!uploadedFiles.unit_image.length && (
                <FileList
                  files={uploadedFiles.unit_image}
                  onDelete={e => handleDeleteFileDownload(e, 'unit_image')}
                />
              )}
            </div>
          </li>
          <li className="item">
            <div className="left-column box">
              <h2>Fotos da ESTRUTURA do polo</h2>
              <p>
                Adicione fotos da estrutura interna do polo, recursos que ele
                tem a oferecer
              </p>
              <p className="bold">no mínimo 4 imagem</p>
            </div>
            <div className="center-column box">
              {!!uploadedFiles.structure_image.length < 5 && (
                <Upload
                  onUpload={e => handleUpload(e, 'structure_image')}
                  message="Clique ou arraste aqui para enviar"
                  backgroundColor="download"
                  accept="image/jpeg, image/png"
                />
              )}

              {!!uploadedFiles.structure_image.length && (
                <FileList
                  files={uploadedFiles.structure_image}
                  onDelete={e => handleDeleteFileDownload(e, 'structure_image')}
                />
              )}
            </div>
          </li>
          <li className="item">
            <div className="left-column box">
              <h2 className="bold">Cursos</h2>
              <p>Clique para selecionar cursos em outra categoria:</p>
            </div>
            <div className="center-column box">
              <Select
                classNamePrefix="selecione"
                isSearchable
                name="category"
                isLoading={categoryLoading}
                placeholder="Selecione uma Categoria"
                options={categoryOptions}
                value={categorySelected}
                onChange={handleChangeCategory}
                required
                className={classes.selectCategory}
                styles={customStyles}
              />
            </div>
          </li>
        </ul>
        <div>
          {courses.length >= 1 && !coursesLoading && (
            <FormGroup row>
              {courses.map(course => {
                const isItemSelected = isSelected(course.id);
                return (
                  <FormControlLabel
                    key={course.id}
                    control={
                      <Checkbox
                        checked={isItemSelected}
                        onChange={e => handleClick(e, course.id)}
                        value={course.id}
                        color="primary"
                      />
                    }
                    label={course.name}
                  />
                );
              })}
            </FormGroup>
          )}
        </div>
        <div className={classes.content}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Cadastrar
          </Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            href="/units"
          >
            Voltar
          </Button>
        </div>
      </form>
    </Container>
  );
}
