import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  background-color: #f8f8f8;
  /* overflow: hidden; */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 1520px;

  .content-wrapper {
    width: 100%;
  }
`;
