import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from '..';

export default function ModalDelete({
  titleModal,
  handleCloseDelete,
  openModalDelete,
  handleSubmitDelete,
  id,
  type,
}) {
  return (
    <Modal
      title={`Deletar - ${titleModal}`}
      handleClose={handleCloseDelete}
      open={openModalDelete}
    >
      <form onSubmit={e => handleSubmitDelete(e, type, id)}>
        <input value={titleModal} onChange={() => {}} disabled />
        <hr />
        <div className="content-btn">
          <Button variant="contained" color="secondary" type="submit">
            Deletar
          </Button>
          <Button
            className="button-cancel"
            variant="contained"
            onClick={handleCloseDelete}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </Modal>
  );
}

ModalDelete.propTypes = {
  titleModal: PropTypes.string.isRequired,
  handleCloseDelete: PropTypes.func.isRequired,
  openModalDelete: PropTypes.bool.isRequired,
  handleSubmitDelete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
