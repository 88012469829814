import React from 'react';
import { DivInput, InputStyle } from './style';
import MaskedInput from 'react-text-mask';

export default function MaskInput(props) {
    return (
        <DivInput>
            <div className="divInput">
                <div className="label-in-middle">
                    <label htmlFor="mask-input">Telefone</label>
                </div>

                <InputStyle>
                    <MaskedInput
                        id="mask-input"
                        mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        value={props.value}
                        onChange={e => props.onChange}
                    />
                </InputStyle>
            </div>
        </DivInput>
    );
}