import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { toast } from 'react-toastify';
import useReactRouter from 'use-react-router';
import axios from 'axios';
import MaskedInput from 'react-text-mask';
import api from '../../services/api';
import InputFlex from '../../components/InputFlex';
import converToSlug from '../../utils/convertToSlug';
import { Container } from './styles';

export default function PartnersForm() {
  const { history } = useReactRouter();

  const [coursesIdsCheckeds, setCoursesIdsCheckeds] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selected, setSelected] = useState([]);
  const [coursesLoading, setCoursesLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [slug, setSlug] = useState('');
  const [categorySelected, setCategorySelected] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([
    { id: '', value: '', label: '', subcategories: [] },
  ]);
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [dataToSent, setDataToSent] = useState({
    name: '',
    state: -1,
    city: -1,
    telephone: '',
    email: '',
    voucher: '',
  });

  useEffect(() => {
    async function loadData() {
      const response = await axios.get(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
      );

      setStates(response.data);
    }
    loadData();
  }, []);

  useEffect(() => {
    async function load() {
      const response = await api.get('/categories');

      const option = response.data.map(e => {
        return {
          id: e.id,
          value: e.id,
          label: e.name,
          subcategories: e.subcategories,
        };
      });
      setCategoryOptions(option);
      setCategoryLoading(false);
    }
    load();
  }, []);

  function handleClick(event, id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  async function loadCourses(category_Id) {
    setCoursesLoading(true);

    const response = await api.get(`/courses/${category_Id}/coursesByCategory`);
    setCourses(response.data);
    setCoursesLoading(false);
  }

  const handleChangeState = e => {
    setDataToSent({ ...dataToSent, state: e.label });

    async function loadCities() {
      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${e.value}/municipios`
      );

      setCities(response.data);
    }

    loadCities();
  };

  const handleChangeCity = event => {
    setDataToSent({ ...dataToSent, city: event.value });
  };

  function handleChangeCategory(value) {
    setCategorySelected(value);
    loadCourses(value.id);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (dataToSent.state === -1) {
        toast.error('Nenhum estado foi selecionado');

        return;
      }

      if (dataToSent.city === -1) {
        toast.error('Nenhuma cidade foi selecionada');
      }
      await api.post('/partners', {
        name: dataToSent.name,
        slug,
        state: dataToSent.state,
        voucher: dataToSent.voucher,
        email: dataToSent.email,
        city: dataToSent.city,
        telephone: dataToSent.telephone,
        course_id: selected,
      });

      toast.success('Sucesso');

      history.push('/partners');
    } catch (error) {
      toast.error('Não foi possível cadastrar esse afiliado');
    }
  }

  const isSelected = courseId => selected.indexOf(courseId) !== -1;
  return (
    <Container>
      <div className="button-newPartner">
        <form onSubmit={handleSubmit}>
          <h1>Cadastrar parceiro</h1>
          <InputFlex
            id="name"
            label="Nome do representante"
            type="text"
            name="name"
            onChange={e =>
              setDataToSent({ ...dataToSent, name: e.target.value })
            }
            required
          />

          <InputFlex
            id="slug"
            label="Apelido"
            type="text"
            name="slug"
            onChange={e => setSlug(converToSlug(e.target.value))}
            required
          />
          <InputFlex
            id="voucher"
            label="Voucher"
            placeholder=""
            type="text"
            name="Voucher"
            onChange={e =>
              setDataToSent({ ...dataToSent, voucher: e.target.value })
            }
            required
          />

          <div style={{ borderTop: '1px solid #e0e0e0' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '15px',
              }}
            >
              <p>Telefone</p>

              <MaskedInput
                id="telephone"
                mask={[
                  '(',
                  /[1-9]/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                label="Telefone"
                type="text"
                name="telephone"
                value={dataToSent.telephone}
                className="inputStyle"
                onChange={e =>
                  setDataToSent({ ...dataToSent, telephone: e.target.value })
                }
                required
              />
            </div>
          </div>

          <InputFlex
            id="email"
            label="Email"
            type="email"
            name="email"
            onChange={e =>
              setDataToSent({ ...dataToSent, email: e.target.value })
            }
            required
          />

          <div style={{ borderTop: '1px solid #e0e0e0' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '15px',
              }}
            >
              <p>Estados</p>
              <Select
                options={states.map(state => {
                  return { label: state.nome, value: state.id };
                })}
                styles={{ control: styles => ({ ...styles, width: '649px' }) }}
                onChange={e => {
                  handleChangeState(e);
                }}
                name="state"
              />
            </div>
          </div>

          <div style={{ borderTop: '1px solid #e0e0e0' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '15px',
              }}
            >
              <p>Cidades</p>

              <Select
                options={cities.map(city => {
                  return { label: city.nome, value: city.nome };
                })}
                styles={{ control: styles => ({ ...styles, width: '649px' }) }}
                onChange={e => {
                  handleChangeCity(e);
                }}
                name="city"
              />
            </div>
          </div>

          <div style={{ borderTop: '1px solid #e0e0e0' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '15px',
              }}
            >
              <p>Curso por categoria</p>
              <Select
                isLoading={categoryLoading}
                options={categoryOptions}
                value={categorySelected}
                onChange={handleChangeCategory}
                styles={{ control: styles => ({ ...styles, width: '649px' }) }}
                name="category"
              />
            </div>
          </div>

          <div>
            {courses.length >= 1 && !coursesLoading && (
              <FormGroup row>
                {courses.map(course => {
                  const isItemSelected = isSelected(course.id);
                  return (
                    <FormControlLabel
                      key={course.id}
                      control={
                        <Checkbox
                          checked={isItemSelected}
                          onChange={e => handleClick(e, course.id)}
                          value={course.id}
                          color="primary"
                        />
                      }
                      label={course.name}
                    />
                  );
                })}
              </FormGroup>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              margin: '10px 0',
            }}
          >
            <Button type="submit" variant="contained" color="primary">
              SALVAR ALTERAÇÕES
            </Button>

            <Button
              className="button-back"
              variant="contained"
              color="primary"
              href="../partners"
            >
              VOLTAR
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
}
