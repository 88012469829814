import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import useReactRouter from 'use-react-router';
import { toast } from 'react-toastify';
import Table from '../../components/Table';
import api from '../../services/api';
import Modal from '../../components/Modal';
import { Container } from './styles';

export default function Units() {
  const { history } = useReactRouter();
  const [data, setData] = useState([]);
  const [unitId, setUnitId] = useState();
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const tableTitle = 'Unidades';

  useEffect(() => {
    async function loadData() {
      const response = await api.get('/units');
      setData(response.data);
    }
    loadData();
  }, []);

  function handleCloseDelete() {
    setOpenModalDelete(!openModalDelete);
  }

  function unitModalDelete(unitId) {
    setUnitId(unitId);
    setOpenModalDelete(true);
  }

  function unitDelete(event) {
    event.preventDefault();

    const newData = [];
    data.forEach(unit => {
      if (unit.id === unitId) return;
      newData.push(unit);
    });

    setData(newData);

    api.delete(`units/${unitId}`);

    toast.success('Deletado com sucesso');
    handleCloseDelete();
  }

  function unitEdit(id) {
    history.push(`unitsEdit/${id}`);
  }

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'Id',
    },
    {
      id: 'city',
      numeric: false,
      disablePadding: true,
      label: 'Nome',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'slug',
      numeric: false,
      disablePadding: false,
      label: 'Apelido',
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: true,
      label: 'Endereço',
    },
    {
      id: 'actions',
      boolean: false,
      disablePadding: false,
      label: 'Ações',
    },
  ];

  return (
    <>
      <Container>
        <div className="button-newUnit">
          <Button
            variant="contained"
            color="primary"
            onClick={() => { }}
            href="/unitsform"
          >
            NOVA UNIDADE
          </Button>
        </div>
        {data && (
          <Table
            rows={data}
            headCells={headCells}
            title={tableTitle}
            onDelete={e => unitModalDelete(e)}
            onEdit={e => unitEdit(e)}
          />
        )}
      </Container>

      <Modal
        title="Excluir Polo"
        handleClose={handleCloseDelete}
        open={openModalDelete}
      >
        <form onSubmit={e => unitDelete(e)}>
          <input value={unitId} hidden />
          <div className="content-btn">
            <Button variant="contained" color="secondary" type="submit">
              Deletar
            </Button>
            <Button
              className="button-back"
              variant="contained"
              color="primary"
              href={handleCloseDelete}
              onClick={handleCloseDelete}
            >
              Cancelar
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
