import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SingIn';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';

import Courses from '../pages/Courses2';
import NewCourses from '../pages/Courses2/newCourses';

import Units from '../pages/Units';

import Partners from '../pages/Partners/index';
import PartnersForm from '../pages/Partners/form';
import PartnersEdit from '../pages/Partners/edit';

import Users from '../pages/Users';
import UsersForm from '../pages/Users/form';
import UsersEdit from '../pages/Users/edit';

import NewUnitV2 from '../pages/Units/newUnit';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/resetpassword" component={ResetPassword} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/courses" component={Courses} isPrivate />
      <Route path="/coursesForm" component={NewCourses} isPrivate />
      <Route path="/coursesEdit/:id" component={NewCourses} isPrivate />
      <Route path="/partners" component={Partners} isPrivate />
      <Route path="/partnersForm" component={PartnersForm} isPrivate />
      <Route path="/partnersEdit/:id" component={PartnersEdit} isPrivate />
      <Route path="/units" component={Units} isPrivate />
      <Route path="/unitsForm" component={NewUnitV2} isPrivate />
      <Route path="/unitsEdit/:id" component={NewUnitV2} isPrivate />
      <Route path="/users" component={Users} isPrivate />
      <Route path="/usersForm" component={UsersForm} isPrivate />
      <Route path="/usersEdit" component={UsersEdit} isPrivate />
      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}
