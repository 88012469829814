/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Form } from '@rocketseat/unform';
import Select from 'react-select';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { toast } from 'react-toastify';
import useReactRouter from 'use-react-router';
import axios from 'axios';
import api from '../../services/api';
import InputFlex from '../../components/InputFlex';
import MaskInput from '../../components/MaskInput';

import { Container } from './styles';

export default function PartnersForm() {
  const { history, match } = useReactRouter();
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [coursesIdsCheckeds, setCoursesIdsCheckeds] = useState([]);
  const [courses, setCourses] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [dataToSent, setDataToSent] = useState({
    name: '',
    slug: '',
    state: '',
    voucher: '',
    city: '',
    telephone: '',
    email: '',
    courses_ids: '',
  });

  useEffect(() => {
    async function loadData() {
      const response = await axios.get(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
      );

      setStates(response.data);
    }
    loadData();
  }, []);

  useEffect(() => {
    const { id } = match.params;
    async function loadData(partnerId) {
      const response = await api.get(`/partners/${partnerId}`);
      setDataToSent({
        name: response.data.name,
        slug: response.data.slug,
        state: response.data.state,
        city: response.data.city,
        telephone: response.data.telephone,
        email: response.data.email,
      });
      console.log(response.data);
      const dataCourseIdCheckeds = {};
      response.data.courses.forEach(course => {
        dataCourseIdCheckeds[course.id] = true;
      });
      setCoursesIdsCheckeds(dataCourseIdCheckeds);
    }
    loadData(id);
  }, []);

  useEffect(() => {
    async function loadData() {
      const response = await api.get('/categories');
      setCategories(response.data);
    }
    loadData();
  }, []);

  const menuItemsCategories = [];
  if (categories.length !== 0) {
    categories.forEach(category => {
      menuItemsCategories.push({
        label: category.name,
        value: category.id,
      });
    });
  }
  async function loadCourses(category_Id) {
    const response = await api.get(`/courses/${category_Id}/coursesByCategory`);

    setCourses(response.data);
  }

  const handleChangeState = e => {
    setDataToSent({ ...dataToSent, state: e.value });

    async function loadCities() {
      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${e.value}/municipios`
      );

      setCities(response.data);
    }

    loadCities();
  };

  const handleChangeCity = event => {
    setDataToSent({ ...dataToSent, city: event.value });
  };

  const handleChangeCategoryId = event => {
    setCategoryId(event.value);

    loadCourses(event.value);
  };

  const handleChangeCoursesUnits = id => event => {
    setCoursesIdsCheckeds({
      ...coursesIdsCheckeds,
      [id]: event.target.checked,
    });
  };

  const formsControlLabels = [[], [], []];
  if (courses.length !== 0) {
    const perPage = Math.ceil(courses.length / 3);
    const perLastPage = courses.length % 2 === 0 ? perPage : perPage - 1;
    let key = 0;
    for (let i = 0; i < formsControlLabels.length; i++) {
      if (i < formsControlLabels.length - 1) {
        for (let j = 0; j < perPage; j++) {
          // Correcao para o laço adicional
          if (typeof courses[key] === 'undefined') break;

          formsControlLabels[i].push(
            <FormControlLabel
              control={
                <Checkbox
                  checked={coursesIdsCheckeds[courses[key].id] === true}
                  value={courses[key].id}
                  onChange={handleChangeCoursesUnits(courses[key].id)}
                />
              }
              label={courses[key].name}
              key={courses[key].id}
            />
          );

          key++;
        }
      } else {
        for (let j = 0; j < perLastPage; j++) {
          // Correcao para o laço adicional
          if (typeof courses[key] === 'undefined') break;

          formsControlLabels[i].push(
            <FormControlLabel
              control={
                <Checkbox
                  checked={coursesIdsCheckeds[courses[key].id] === true}
                  value={courses[key].id}
                  onChange={handleChangeCoursesUnits(courses[key].id)}
                />
              }
              label={courses[key].name}
              key={courses[key].id}
            />
          );

          key++;
        }
      }
    }
  }

  async function handleSubmit(e) {
    const { id } = match.params;
    e.preventDefault();
    const data = new FormData();
    data.append('name', dataToSent.name);
    data.append('slug', dataToSent.slug);
    data.append('voucher', dataToSent.voucher);
    data.append('state', dataToSent.state);
    data.append('city', dataToSent.city);
    data.append('telephone', dataToSent.telephone);
    data.append('email', dataToSent.email);
    data.append('courses_ids', JSON.stringify(coursesIdsCheckeds));

    await api.put(`/partners/${id}`, data);

    toast.success('Editado com Sucesso');
    history.push('/partners');
  }
  return (
    <Container>
      <div className="button-newPartner">
        <Form
          onSubmit={handleSubmit}
          method="POST"
          encType="multipart/form-data"
        >
          <h1>Editar parceiro</h1>
          <InputFlex
            id="name"
            label="Nome do representante"
            type="text"
            value={dataToSent.name}
            name="name"
            onChange={e =>
              setDataToSent({ ...dataToSent, name: e.target.value })
            }
            required
          />

          <InputFlex
            id="slug"
            label="Apelido"
            type="text"
            value={dataToSent.slug}
            name="slug"
            required
            onChange={e =>
              setDataToSent({ ...dataToSent, slug: e.target.value })
            }
          />
          <InputFlex
            id="voucher"
            label="Voucher"
            type="text"
            value={dataToSent.slug}
            name="Voucher"
            required
            onChange={e =>
              setDataToSent({ ...dataToSent, voucher: e.target.value })
            }
          />

          <MaskInput
            id="telephone"
            value={dataToSent.telephone}
            name="telephone"
            onChange={e =>
              setDataToSent({ ...dataToSent, telephone: e.target.value })
            }
            required
          />

          <InputFlex
            id="email"
            label="Email"
            type="text"
            value={dataToSent.email}
            name="email"
            required
            onChange={e =>
              setDataToSent({ ...dataToSent, email: e.target.value })
            }
          />

          <div style={{ borderTop: '1px solid #e0e0e0' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '15px',
              }}
            >
              <p>Estados</p>
              <Select
                value={dataToSent.state}
                options={states.map(state => {
                  return { label: state.nome, value: state.id };
                })}
                styles={{ control: styles => ({ ...styles, width: '649px' }) }}
                onChange={e => {
                  handleChangeState(e);
                }}
                name="state"
              />
            </div>
          </div>

          <div style={{ borderTop: '1px solid #e0e0e0' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '15px',
              }}
            >
              <p>Cidades</p>

              <Select
                value={dataToSent.city}
                options={cities.map(city => {
                  return { label: city.nome, value: city.id };
                })}
                styles={{ control: styles => ({ ...styles, width: '649px' }) }}
                onChange={e => {
                  handleChangeCity(e);
                }}
                name="city"
              />
            </div>
          </div>

          <div style={{ borderTop: '1px solid #e0e0e0' }}>
            <h4 style={{ padding: '15px 15px 0px 15px' }}>Cursos</h4>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '15px',
              }}
            >
              <p>Curso por categoria</p>
              <Select
                options={menuItemsCategories}
                styles={{ control: styles => ({ ...styles, width: '649px' }) }}
                onChange={e => {
                  handleChangeCategoryId(e);
                }}
                name="category"
              />
            </div>
          </div>

          <div className="courses-checklist">
            <FormControl component="fieldset">
              <FormGroup key="0">{formsControlLabels[0]}</FormGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormGroup key="1">{formsControlLabels[1]}</FormGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormGroup key="2">{formsControlLabels[2]}</FormGroup>
            </FormControl>
          </div>

          <Button type="submit" variant="contained" color="primary">
            SALVAR ALTERAÇÕES
          </Button>
          <Button
            className="button-back"
            variant="contained"
            color="primary"
            href="../partners"
          >
            VOLTAR
          </Button>
        </Form>
      </div>
    </Container>
  );
}
