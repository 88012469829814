import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from '..';

export default function ModalEdit({
  titleModal,
  handleCloseEdit,
  openModalEdit,
  handleSubmitEdit,
  name,
  setName,
  id,
  type,
}) {
  return (
    <Modal
      title={`Editar uma - ${titleModal}`}
      handleClose={handleCloseEdit}
      open={openModalEdit}
    >
      <form onSubmit={e => handleSubmitEdit(e, type, id)}>
        <input
          placeholder={`Digite o nome da ${titleModal}`}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <hr />
        <div className="content-btn">
          <Button
            className="button-create"
            variant="contained"
            type="submit"
            color="primary"
          >
            Editar
          </Button>
          <Button
            className="button-cancel"
            variant="contained"
            onClick={handleCloseEdit}
            color="secondary"
          >
            Cancelar
          </Button>
        </div>
      </form>
    </Modal>
  );
}

ModalEdit.propTypes = {
  titleModal: PropTypes.string.isRequired,
  handleCloseEdit: PropTypes.func.isRequired,
  openModalEdit: PropTypes.bool.isRequired,
  handleSubmitEdit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
};
