import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1208px;
  box-sizing: border-box;
  margin: auto;
  margin-bottom: 24px;
  padding: 0 24px;
  align-items: center;

  form {
    margin: 24px 0;
  }
  hr {
    height: 1px;
    background: #e0e0e0;
    border: none;
    margin-top: 16px;
  }

  .item {
    padding: 24px 0;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 24px;
    grid-auto-flow: row;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
  }

  .box h2 {
    font-size: 20px;
  }

  .box p {
    font-size: 14px;
    margin-top: 16px;
    opacity: 0.6;
  }
  .bold {
    font-weight: 700;
  }
  /* .box p:nth-child(3) {
    font-weight: 700;
  } */

  div.left-column.flex {
    align-items: center;
    display: flex;
  }

  .center-column {
    input {
      height: 56px;
      border: 1px solid #ffff;
      border-radius: 4px;
      overflow: hidden;
    }
  }
  .divider {
    margin-top: 70px;
    margin-bottom: 10px;
    text-align: center;
  }

  .question-item {
    padding: 24px 0;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 24px;
    grid-auto-flow: row;
    border-bottom: 1px solid #e0e0e0;
    .title-question {
      margin-top: 10px;
      margin-bottom: 10px;
      &:first-child {
        margin-top: 0px;
      }
    }
  }

  .content-input {
    display: flex;
    /* height: 38px; */
    width: 100%;
    /* margin: 16px; */
    input {
      width: 80%;
      height: 38px;
      padding: 8px;
      margin-right: 16px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }
  }
  .content-list {
    display: block;
    /* margin: 16px; */
    input.input-list {
      height: 38px;
      padding: 8px;
      margin-right: 16px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }
  }
  .MuiListItemText-primary {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }
  .MuiList-root.list-secondary {
    padding-left: 16px;
    padding-top: 0px;
  }
  .MuiListItem-gutters {
    padding-top: 0;
  }
  .MuiListItemText-root.list-secondary-text {
    span {
      font-weight: normal;
      font-size: 16px;
    }
  }
  .MuiDivider-root {
    margin-bottom: 16px;
  }
  div.content-select {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    /* padding: 0 30px; */

    .wrapper-select {
      display: block;
      width: 100%;

      label {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;

        color: #707070;
      }
    }
    .wrapper-select.category {
      margin-bottom: 24px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    .item {
      grid-template-columns: minmax(312px, 1fr);
    }
  }
  .wrapper-list-blocks {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MuiListItemSecondaryAction-root {
    position: unset !important;
    transform: initial !important;
    margin-left: 14px;
  }
`;
