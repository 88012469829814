import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container } from './styles';

export default function Sidebar() {
  return (
    <Container>
      <section>
        <NavLink to="/partners" activeClassName="actived">
          Afiliados
        </NavLink>
        <NavLink to="/units" activeClassName="actived">
          Unidades
        </NavLink>
        <NavLink to="/courses" activeClassName="actived">
          Cursos
        </NavLink>
        <NavLink to="/users" activeClassName="actived">
          Usuários
        </NavLink>
      </section>
    </Container>
  );
}
