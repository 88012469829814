import styled from 'styled-components';

export const Container = styled.aside`
  height: 100%;
  width: 239px;
  background-color: #fff;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      padding: 16px;
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);

      &:hover {
        background-color: #ebebeb;
      }
    }
    .actived {
      color: #2196f3;
      font-weight: 700;
      background-color: #ebebeb;
    }
  }
`;
