import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import Select from 'react-select';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import useReactRouter from 'use-react-router';
import ListItem from '@material-ui/core/ListItem';
import { toast } from 'react-toastify';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Upload from '../../../components/Upload';
import FileList from '../../../components/FileList';
import api from '../../../services/api';
import ModalDelete from '../../../components/Modal/ModalDelete';
import ModalEdit from '../../../components/Modal/ModalEdit';
import { onlyNumber } from '../../../utils/convertToSlug';

import { Container } from './styles';

export default function NewCourses() {
  const useStyles = makeStyles(theme => ({
    textField: {
      height: '56px',
      width: '100%',
      background: '#fff',
      border: '1px solid #E0E0E0',
      borderRadius: '4px',
    },
    textFieldBlocos: {
      height: '38px',
      width: '100%',
      background: '#fff',
      border: '1px solid #E0E0E0',
      borderRadius: '4px',
    },
    btn: {
      minHeight: '56px',
      marginLeft: '10px',
    },
    color: {
      background: '#fff',
      border: '1px solid #E0E0E0',
      borderRadius: '4px',
    },
    selectCategory: {
      height: '36px',
      marginBottom: '16px',
    },
    content: {
      display: 'flex',
      justifyContent: 'flex-end',
      maxWidth: '1208px',
      marginTop: '24px',
      marginBottom: '24px',
    },
    button: {
      marginRight: '20px',
    },
    switch: {
      marginRight: '16px',
    },
  }));
  const classes = useStyles();
  const { history, match } = useReactRouter();
  const [name, setName] = useState('');
  const [nameEdit, setNameEdit] = useState('');

  const [about, setAbout] = useState('');
  const [laborMarket, setLaborMarket] = useState('');
  const [averageSalary, setAverageSalary] = useState('');
  const [workload, setWorkload] = useState('');
  const [link, setLink] = useState('');
  const [online, setOnline] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [modules, setModules] = useState([]);
  const [nameBlocks, setNameBlocks] = useState('');
  const [blockLoading, setBlockLoading] = useState(false);
  const [moduleLoading, setModuleLoading] = useState(false);
  const [nameModules, setNameModules] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({
    background: [],
    card: [],
  });
  const [categorySelected, setCategorySelected] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([
    { id: '', value: '', label: '', subcategories: [] },
  ]);
  const [categoryLoading, setCategoryLoading] = useState(true);

  const [subcategorySelected, setSubcategorySelected] = useState(null);
  const [subcategoryOptions, setSubcategoryOptions] = useState([
    { id: '', value: '', label: '' },
  ]);
  const [subcategoryIsDisabled, setSubcategoryIsDisabled] = useState(true);
  const [active, setActive] = useState(true);

  const [titleModal, setTitleModal] = useState('');
  const [idModal, setIdModal] = useState('');

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const [typeName, setTypeName] = useState('');

  function handleModalEdit(titleName, id, type) {
    setOpenModalEdit(true);
    setTitleModal(titleName);
    setNameEdit(titleName);
    setTypeName(type);
    setIdModal(id);
  }

  function handleModalDelete(titleName, id, type) {
    setOpenModalDelete(true);
    setTitleModal(titleName);
    setTypeName(type);
    setIdModal(id);
  }

  function handleCloseEdit() {
    setOpenModalEdit(!openModalEdit);
  }

  function handleCloseDelete() {
    setOpenModalDelete(!openModalDelete);
  }

  async function handleSubmitDelete(event, type, id) {
    event.preventDefault();
    let url;

    if (type === 'block') url = `blocks/${id}`;

    if (type === 'module') url = `modules/${id}`;

    try {
      await api.delete(`/${url}`);
      toast.success(`${titleModal} deletado com sucesso`);
      setOpenModalDelete(!openModalDelete);
      setTitleModal('');

      if (type === 'block') {
        const newBlock = blocks.filter(block => block.id !== id);

        setBlocks(newBlock);
      } else if (type === 'module') {
        const newModule = modules.filter(module => module.id !== id);
        setModules(newModule);
      }
    } catch (error) {
      toast.error(`Falha ao deletar - ${titleModal}`);
    }
  }

  async function handleSubmitEdit(event, type, id) {
    event.preventDefault();
    let url;

    if (type === 'block') url = `blocks/${id}`;

    if (type === 'module') url = `modules/${id}`;

    try {
      await api.put(`/${url}`, { name: nameEdit });
      toast.success(`${titleModal} editado com sucesso`);
      setOpenModalEdit(!openModalEdit);

      if (type === 'block') {
        const newBlock = blocks.map(block => {
          if (block.id === id) {
            block.name = nameEdit;
          }
          return block;
        });

        setBlocks(newBlock);
      }
      if (type === 'module') {
        const newModule = modules.map(module => {
          if (module.id === id) {
            module.name = nameEdit;
          }
          return module;
        });

        setModules(newModule);
      }
      setTitleModal('');
      setNameEdit('');
    } catch (error) {
      toast.error(`Erro ao editar ${titleModal}`);
    }
  }

  async function handleDeleteFileDownload(id, type) {
    await api.delete(`files/${id}`);

    setUploadedFiles(prevState => {
      const removeValue = prevState[type].filter(value => id !== value.id);
      return {
        ...prevState,
        [type]: removeValue,
      };
    });
  }

  function updateFile(id, type, data) {
    setUploadedFiles(prevState => {
      const newValue = prevState[type].map(value => {
        return id === value.id ? { ...value, ...data } : value;
      });

      return {
        ...prevState,
        [type]: newValue,
      };
    });
  }

  function processUpload(upFile, type) {
    const data = new FormData();

    data.append('file', upFile.file, upFile.name);

    api
      .post(`files?img_type=${type}`, data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total), 10);

          updateFile(upFile.id, type, {
            progress,
          });
        },
      })
      .then(response => {
        updateFile(upFile.id, type, {
          uploaded: true,
          id: response.data.id,
          url: response.data.url,
          type,
        });
      })
      .catch(response => {
        console.log('err', response);
        updateFile(upFile.id, type, {
          error: true,
        });
      });
  }

  function handleUpload(files, type) {
    const uploaded = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
      type,
    }));
    setUploadedFiles(prevState => {
      return {
        ...prevState,
        [type]: uploadedFiles[type].concat(uploaded),
      };
    });
    uploaded.forEach(e => processUpload(e, type));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const { id } = match.params;
    const files = uploadedFiles.background.concat(uploadedFiles.card);
    const file_id = files.map(f => f.id);
    const block_id = blocks.map(b => b.id);
    const subcategory_id = subcategorySelected.id;
    if (id) {
      try {
        await api.put(`v2/courses/${id}`, {
          name,
          description: about,
          marketplace_course: laborMarket,
          average_salary: averageSalary,
          workload,
          subcategory_id,
          active,
          file_id,
          block_id,
          link,
          online,
        });

        toast.success('Unidade criada com sucesso');
        history.push('/courses');
      } catch (error) {
        toast.error('Não foi possível criar essa unidade');
      }
    } else {
      try {
        await api.post('v2/courses', {
          name,
          description: about,
          marketplace_course: laborMarket,
          average_salary: averageSalary,
          workload,
          subcategory_id,
          active,
          file_id,
          block_id,
          link,
          online,
        });

        toast.success('Unidade Criada com sucesso');
        history.push('/courses');
      } catch (error) {
        toast.error('Não possível criar essa unidade');
      }
    }
  }
  async function handleAddBlock() {
    try {
      const { id } = match.params;
      setBlockLoading(true);
      let response;
      if (id) {
        response = await api.post('blocks', {
          name: nameBlocks,
          course_id: id,
        });
      } else {
        response = await api.post('blocks', { name: nameBlocks });
      }
      setNameBlocks('');
      setBlocks(prevState => {
        const newBlock = [...prevState];

        newBlock.push({ id: response.data.id, name: response.data.name });
        return newBlock;
      });
      toast.success('Bloco adicionado com sucesso');
      setBlockLoading(false);
    } catch (error) {
      toast.error('Não foi possível adicionar esse bloco');
      setBlockLoading(false);
    }
  }

  async function handleAddModulo(blockId, nameMod) {
    try {
      setModuleLoading(true);

      const response = await api.post('modules', {
        name: nameModules[nameMod],
        block_id: blockId,
      });
      setNameModules({ [nameMod]: '' });

      setModules(prevState => {
        const newModule = [...prevState];

        newModule.push({
          id: response.data.id,
          name: response.data.name,
          block_id: response.data.block_id,
        });
        return newModule;
      });
      toast.success('Módulo adicionado com sucesso');
      setModuleLoading(false);
    } catch (error) {
      toast.success('Não foi possível adicionar esse módulo');
      setModuleLoading(false);
    }
  }

  const handleChange = nameModule => event => {
    setNameModules({ [nameModule]: event.target.value });
  };

  function handleChangeCategory(value) {
    setCategorySelected(value);
    const options = value.subcategories.map(e => {
      return {
        id: e.id,
        value: e.id,
        label: e.name,
      };
    });
    setSubcategorySelected(null);
    setSubcategoryOptions(options);
    setSubcategoryIsDisabled(false);
  }

  function handleChangeSubcategory(value) {
    setSubcategorySelected(value);
  }

  useEffect(() => {
    async function load() {
      const response = await api.get('/categories');

      const option = response.data.map(e => {
        return {
          id: e.id,
          value: e.id,
          label: e.name,
          subcategories: e.subcategories,
        };
      });
      setCategoryOptions(option);
      setCategoryLoading(false);
    }
    load();
  }, []);

  useEffect(() => {
    const { id } = match.params;

    async function loadData(dataId) {
      const response = await api.get(`v2/courses/${dataId}`);

      setName(response.data.name);
      setAbout(response.data.description);
      setLaborMarket(response.data.marketplace_course);
      setAverageSalary(response.data.average_salary);
      setWorkload(response.data.workload);
      setOnline(response.data.online);
      setLink(response.data.link);

      if (response.data.files.length > 0) {
        setUploadedFiles(() => {
          const files = response.data.files.map(element => ({
            ...element,
            uploaded: true,
            preview: element.url,
          }));
          return {
            card: files.filter(f => f.img_type === 'card'),
            background: files.filter(f => f.img_type === 'background'),
          };
        });
      }
      if (response.data.blocks.length > 0) {
        setBlocks(response.data.blocks);

        response.data.blocks.forEach(b => {
          if (b.modules.length > 0) {
            b.modules.forEach(module =>
              setModules(prevState => {
                const newModule = [...prevState];

                newModule.push({
                  ...module,
                });
                return newModule;
              })
            );
          }
        });
      }
      if (response.data.subcategories) {
        setSubcategorySelected({
          id: response.data.subcategories.id,
          value: response.data.subcategories.name,
          label: response.data.subcategories.name,
        });
        setCategorySelected({
          id: response.data.subcategories.categories.id,
          value: response.data.subcategories.categories.name,
          label: response.data.subcategories.categories.name,
        });
        setSubcategoryIsDisabled(false);
      }
    }
    if (id) {
      loadData(id);
    }
  }, [match.params]);

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <h1>Novo Curso</h1>
        <hr />
        <ul>
          <li className="item">
            <div className="left-column box">
              <h2>Nome do Curso</h2>
            </div>
            <div className="center-column  box">
              <TextField
                id="name-course"
                className={classes.textField}
                placeholder="Nome do curso"
                label="Nome do curso"
                margin="none"
                variant="outlined"
                inputProps={{ 'aria-label': 'bare' }}
                rowsMax="1"
                rows="1"
                multiline
                fullWidth
                value={name}
                onChange={e => setName(e.target.value)}
                style={{ zIndex: 0 }}
                required
              />
            </div>
          </li>
          <li className="item">
            <div className="left-column box">
              <h2>Sobre o Curso</h2>
            </div>
            <div className="center-column box">
              <TextField
                id="outlined-bare"
                className={classes.color}
                placeholder="Sobre o Curso"
                label="Sobre o Curso"
                margin="none"
                variant="outlined"
                inputProps={{ 'aria-label': 'bare' }}
                multiline
                rows="5"
                fullWidth
                value={about}
                onChange={e => setAbout(e.target.value)}
                style={{ zIndex: 0 }}
              />
            </div>
          </li>
          <li className="item">
            <div className="left-column  box">
              <h2>Mercado de Trabalho</h2>
            </div>
            <div className="center-column box">
              <TextField
                id="labor-market"
                className={classes.color}
                placeholder="Mercado de Trabalho"
                label="Mercado de Trabalho"
                margin="none"
                variant="outlined"
                inputProps={{ 'aria-label': 'bare' }}
                rows="5"
                multiline
                fullWidth
                value={laborMarket}
                onChange={e => setLaborMarket(e.target.value)}
                style={{ zIndex: 0 }}
              />
            </div>
          </li>
          <li className="item">
            <div className="left-column box">
              <h2>Média Salarial</h2>
            </div>
            <div className="center-column box">
              <TextField
                id="average-salary"
                className={classes.textField}
                placeholder="Média Salarial"
                label="Média Salarial"
                margin="none"
                variant="outlined"
                inputProps={{ 'aria-label': 'bare' }}
                rowsMax="1"
                rows="1"
                multiline
                fullWidth
                value={averageSalary}
                onChange={e => setAverageSalary(onlyNumber(e.target.value))}
                style={{ zIndex: 0 }}
              />
            </div>
          </li>
          <li className="item">
            <div className="left-column box">
              <h2>Carga Horária</h2>
            </div>
            <div className="center-column box">
              <TextField
                id="workload"
                className={classes.textField}
                placeholder="Carga Horária"
                label="Carga Horária"
                margin="none"
                variant="outlined"
                inputProps={{ 'aria-label': 'bare' }}
                rowsMax="1"
                rows="1"
                multiline
                fullWidth
                value={workload}
                onChange={e => setWorkload(onlyNumber(e.target.value))}
                style={{ zIndex: 0 }}
              />
            </div>
          </li>
          <li className="item">
            <div className="left-column box">
              <h2>Imagem do Curso - Card</h2>
              <p>
                Adicione uma imagem quadrada para servir como card na página do
                curso
              </p>
            </div>
            <div className="center-column box">
              {!!uploadedFiles.card.length <= 1 && (
                <Upload
                  onUpload={e => handleUpload(e, 'card')}
                  message="Clique ou arraste aqui para enviar"
                  backgroundColor="download"
                  accept="image/jpeg, image/png"
                />
              )}

              {!!uploadedFiles.card.length && (
                <FileList
                  files={uploadedFiles.card}
                  onDelete={e => handleDeleteFileDownload(e, 'card')}
                />
              )}
            </div>
          </li>
          <li className="item">
            <div className="left-column box">
              <h2>Imagem do Curso - Banner</h2>
              <p>
                Adicione uma imagem grande para servir como banner/fundo na
                página do curso
              </p>
            </div>
            <div className="center-column box">
              {!!uploadedFiles.background.length <= 1 && (
                <Upload
                  onUpload={e => handleUpload(e, 'background')}
                  message="Clique ou arraste aqui para enviar"
                  backgroundColor="download"
                  accept="image/jpeg, image/png"
                />
              )}

              {!!uploadedFiles.background.length && (
                <FileList
                  files={uploadedFiles.background}
                  onDelete={e => handleDeleteFileDownload(e, 'background')}
                />
              )}
            </div>
          </li>
          <li className="item">
            <div className="left-column box">
              <h2>Adicionar os blocos</h2>
            </div>
            <div className="center-column box">
              <div className="content-input">
                <TextField
                  id="blocks"
                  className={classes.textField}
                  placeholder="Adicionar Bloco"
                  label="Adicionar Bloco"
                  margin="none"
                  variant="outlined"
                  inputProps={{ 'aria-label': 'bare' }}
                  rowsMax="1"
                  rows="1"
                  multiline
                  fullWidth
                  value={nameBlocks}
                  onChange={e => setNameBlocks(e.target.value)}
                  style={{ zIndex: 0 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleAddBlock}
                  className={classes.btn}
                >
                  {!blockLoading ? 'Adicionar' : 'Carregando..'}
                </Button>
              </div>

              <div className="content-list">
                <List>
                  {blocks &&
                    blocks.map(block => (
                      <div key={`block-${block.id}`}>
                        <ListItem>
                          <div className="wrapper-list-blocks">
                            <ListItemText primary={block.name} />
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleModalEdit(block.name, block.id, 'block')
                              }
                              color="primary"
                            >
                              <EditIcon />
                            </IconButton>

                            <IconButton
                              size="small"
                              onClick={() =>
                                handleModalDelete(block.name, block.id, 'block')
                              }
                              color="secondary"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                          <ListItemSecondaryAction>
                            <input
                              type="text"
                              className="input-list"
                              placeholder="Adicionar Módulo"
                              value={nameModules[`module-${block.id}`]}
                              onChange={handleChange(`module-${block.id}`)}
                            />
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() =>
                                handleAddModulo(block.id, `module-${block.id}`)
                              }
                            >
                              {!moduleLoading
                                ? 'Adicionar Módulos'
                                : 'Carregando..'}
                            </Button>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <List
                          className="list-secondary"
                          key={`module-${block.id}`}
                        >
                          {modules &&
                            modules
                              .filter(m => m.block_id === block.id)
                              .map(module => (
                                <ListItem key={`modulelist-${module.id}`}>
                                  <div className="wrapper-list-blocks">
                                    <ListItemText
                                      primary={module.name}
                                      className="list-secondary-text"
                                    />
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleModalEdit(
                                          module.name,
                                          module.id,
                                          'module'
                                        )
                                      }
                                      color="primary"
                                    >
                                      <EditIcon />
                                    </IconButton>

                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        handleModalDelete(
                                          module.name,
                                          module.id,
                                          'module'
                                        )
                                      }
                                      color="secondary"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                </ListItem>
                              ))}
                        </List>
                        <Divider />
                      </div>
                    ))}
                </List>
              </div>
            </div>
          </li>
          <li className="item">
            <div className="left-column box">
              <h2>Selecionar Categoria</h2>
            </div>
            <div className="center-column box">
              <div className="content-select">
                <div className="wrapper-select category">
                  <Select
                    name="Category"
                    className="select"
                    isLoading={categoryLoading}
                    placeholder="Selecione uma Categoria"
                    options={categoryOptions}
                    value={categorySelected}
                    onChange={handleChangeCategory}
                    required
                  />
                </div>
              </div>
              <div className="content-select">
                <div className="wrapper-select">
                  <Select
                    name="Subcategory"
                    className="select"
                    placeholder="Selecione uma Subcategoria"
                    isDisabled={subcategoryIsDisabled}
                    options={subcategoryOptions}
                    value={subcategorySelected}
                    onChange={handleChangeSubcategory}
                    required
                  />
                </div>
              </div>
            </div>
          </li>
          <li className="item">
            <div className="left-column box">
              <h2>Link para online</h2>
            </div>
            <div className="center-column box">
              <TextField
                id="blocks"
                className={classes.textField}
                placeholder="Link para online"
                label="Link para online"
                margin="none"
                variant={!online ? 'filled' : 'outlined'}
                inputProps={{ 'aria-label': 'bare' }}
                rowsMax="1"
                rows="1"
                multiline
                fullWidth
                value={link}
                onChange={e => setLink(e.target.value)}
                style={{ zIndex: 0 }}
                disabled={!online}
              />
              <FormControlLabel
                className={classes.switch}
                control={
                  <Checkbox
                    checked={online}
                    onChange={() => setOnline(!online)}
                    value="checkedA"
                    color="primary"
                  />
                }
                labelPlacement="start"
                label="Curso Online"
              />
            </div>
          </li>
        </ul>

        <div />
        <div className={classes.content}>
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                checked={active}
                onChange={() => setActive(!active)}
                value="checkedA"
                color="primary"
              />
            }
            labelPlacement="start"
            label={active ? 'Publicado' : 'Não publicado'}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Salvar
          </Button>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            href="../units"
          >
            Voltar
          </Button>
        </div>
      </form>
      <ModalDelete
        type={typeName}
        titleModal={titleModal}
        handleCloseDelete={handleCloseDelete}
        openModalDelete={openModalDelete}
        handleSubmitDelete={handleSubmitDelete}
        id={idModal}
      />
      <ModalEdit
        titleModal={titleModal}
        handleCloseEdit={handleCloseEdit}
        openModalEdit={openModalEdit}
        handleSubmitEdit={handleSubmitEdit}
        name={nameEdit}
        setName={setNameEdit}
        id={idModal}
        type={typeName}
      />
    </Container>
  );
}
