import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';
import useReactRouter from 'use-react-router';
import Modal from '../../components/Modal';
import { Container } from './styles';
import api from '../../services/api';
import Table from '../../components/Table';

export default function Users() {
  const { history } = useReactRouter();
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState();
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const tableTitle = 'Usuários';

  useEffect(() => {
    async function loadData() {
      const response = await api.get('/users');
      setData(response.data);
    }
    loadData();
  }, []);

  function userEdit(id) {
    history.push(`usersEdit/${id}`);
  }

  Object.keys(data).forEach(key => {
    data[key].actions = [
      <CreateIcon
        color="primary"
        onClick={() => userEdit(data[key].id)}
        key={`edit-${data.id}`}
      />,
      <DeleteIcon
        color="secondary"
        onClick={() => userEdit(data[key].id)}
        key={`delete-${data.id}`}
      />,
    ];
  });

  function handleCloseDelete() {
    setOpenModalDelete(!openModalDelete);
  }

  function userModalDelete(id) {
    setUserId(id);
    setOpenModalDelete(true);
  }

  function userDelete(event) {
    event.preventDefault();

    const newData = [];
    data.forEach(user => {
      if (user.id === userId) return;
      newData.push(user);
    });

    setData(newData);

    api.delete(`users/${userId}`);

    toast.success('Deletado com sucesso');
    handleCloseDelete();
  }

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'Id',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Nome',
    },
    {
      id: 'surname',
      numeric: false,
      disablePadding: false,
      label: 'Sobrenome',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'actions',
      boolean: false,
      disablePadding: false,
      label: 'Ações',
    },
  ];

  return (
    <>
      <Container>
        <div className="button-newUser">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {}}
            href="/usersform"
          >
            NOVO USUÁRIO
          </Button>
        </div>
        {data && (
          <Table
            rows={data}
            headCells={headCells}
            title={tableTitle}
            onDelete={e => userModalDelete(e)}
            onEdit={e => userEdit(e)}
          />
        )}
      </Container>

      <Modal
        title="Excluir Usuário"
        handleClose={handleCloseDelete}
        open={openModalDelete}
      >
        <form onSubmit={e => userDelete(e)}>
          <input value={userId} hidden />
          <div className="content-btn">
            <Button variant="contained" color="secondary" type="submit">
              Deletar
            </Button>
            <Button
              className="button-back"
              variant="contained"
              color="primary"
              href={handleCloseDelete}
              onClick={handleCloseDelete}
            >
              Cancelar
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
