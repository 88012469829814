import styled from 'styled-components';

export const GroupButtons = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;

  .button-create {
    background: #2196f3;
    margin-right: 10px;
    color: #fff;
    box-shadow: none;
  }
  .button-create:hover {
    background-color: #2199f9;
    box-shadow: none;
  }

  .button-cancel {
    border: 2px solid #707070;
    background-color: #fff;
    box-shadow: none;
  }
  .button-cancel:hover {
    background: #fff;
    box-shadow: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;

  div.content-select {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 70px;
    padding: 0 30px;

    .wrapper-select {
      display: block;
      width: 437px;
      height: 38px;
      label {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;

        color: #707070;
      }
    }

    .wrapper-button {
      button {
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 1.25px;
          color: #707070;
        }
      }
    }

    .actions-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;
    }
  }
  .content-select.subcategory {
    display: flex;
    /* width: 1200px; */
  }

  .button-back {
    background-color: #ffffff;
    border: 2px solid #707070;
    box-shadow: none;
    margin: 0 10px;
    span {
      color: #707070;
    }

    &:hover {
      background-color: #ffffff;
      box-shadow: none;
    }
    a {
      background-color: #2199f9;
      border: 2px solid #2199f9;
      box-shadow: none;

      &:hover {
        background-color: #2199f9;
        box-shadow: none;
      }
    }
  }

  .button-newCourse {
    display: flex;
    align-items: center;
    a {
      background-color: #2196f3;
      border: 1px solid #2196f3;
      box-shadow: none;
      span {
        color: #fff;
      }

      &:hover {
        background-color: #2199f9;
        box-shadow: none;
      }
    }
    button {
      background-color: #2196f3;
      border: 1px solid #2196f3;
      box-shadow: none;
      span {
        color: #fff;
      }

      &:hover {
        background-color: #2199f9;
        box-shadow: none;
      }
    }
  }
`;
