import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import useReactRouter from 'use-react-router';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import storage from 'redux-persist/lib/storage';
import Button from '@material-ui/core/Button';

import { Container } from './styles';

export default function Header() {
  const { history } = useReactRouter();
  function handleLogout() {
    storage.removeItem('persist:pages-admin');
    history.go('/signIn');
  }
  return (
    <Container>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className="title">
            Administrador
          </Typography>
          <Button color="inherit" onClick={handleLogout}>
            Sair
          </Button>
        </Toolbar>
      </AppBar>
    </Container>
  );
}
