import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
    
    form {
      width: 100%;
      padding: 0 30px;
      .inputStyle {
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 4px;
        height: 36px;
        width: 649px;
        padding: 0px 10px;
    }
  }

    .courses-checklist {
      display: flex;
      justify-content: flex;
    }
    
    .wrapper-button {
      padding-top: 18px;
      padding-left: 16px;
      button {
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 1.25px;
          color: #707070;
        }
      }
    }
  }
  .button-newPartner {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 30px;
    a {
      background-color: #2196f3;
      border: 2px solid #ffffff;
      box-shadow: none;
      span {
        color: #fff;
      }

      &:hover {
        background-color: #2199f9;
        box-shadow: none;
      }
    }
    button {
      background-color: #2196f3;
      border: 1px solid #2196f3;
      box-shadow: none;
      span {
        color: #fff;
      }

      &:hover {
        background-color: #2199f9;
        box-shadow: none;
      }
    }
  }
  a.button-back {
      background-color: #ffffff;
      border: 2px solid #707070;
      box-shadow: none;
      margin: 0 10px;
      span {
        color: #707070;
      }

      &:hover {
        background-color: #ffffff;
        box-shadow: none;
      }
    button {
      background-color: #2199f9;
      border: 2px solid #2199f9;
      box-shadow: none;
      span {
        color: #fff;
      }

      &:hover {
        background-color: #2199f9;
        box-shadow: none;
      }
    }
`;
