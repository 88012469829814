import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Table from '../../components/Table';
import api from '../../services/api';

// import { Container } from './styles';

export default function Dashboard() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function loadData() {
      const response = await api.get('/partern');
      setData(response.data);
    }
    loadData();
  }, []);

  const headCells = [
    {
      id: 'city',
      numeric: false,
      disablePadding: true,
      label: 'Cidade',
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: true,
      label: 'Endereço',
    },
  ];

  return (
    <div>
      <Button variant="contained" color="primary">
        Nova unidade
      </Button>
      <Table rows={data} headCells={headCells} />
    </div>
  );
}
