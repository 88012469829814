import React from 'react';
import { DivInput, InputStyle } from './style';

export default function InputFlex({
  id,
  label,
  name,
  value,
  type,
  onChange,
  required,
  multiple,
  className,
  placeholder,
}) {
  return (
    <DivInput>
      <div className="divInput">
        <div className="label-in-middle">
          <label htmlFor={id}>{label}</label>
        </div>

        <InputStyle>
          <input
            id={id}
            type={type}
            name={name}
            value={value}
            className={`inputStyle ${className}`}
            onChange={onChange}
            required={required === true}
            multiple={multiple === true}
            placeholder={placeholder}
          />
        </InputStyle>
      </div>
    </DivInput>
  );
}
