import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import useReactRouter from 'use-react-router';
import InputFlex from '../../components/InputFlex';
import api from '../../services/api';

import { Container } from './styles';

export default function UsersForm() {
  const { history } = useReactRouter();
  const [dataToSent, setDataToSent] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
  });

  async function handleSubmit(e) {
    e.preventDefault();
    const data = new FormData();
    data.append('name', dataToSent.name);
    data.append('surname', dataToSent.surname);
    data.append('email', dataToSent.email);
    data.append('password', dataToSent.password);

    await api.post('/users', data);

    toast.success(' Criado com sucesso');
    history.push('/users');
  }
  return (
    <Container>
      <div className="button-newUser">
        <Form onSubmit={handleSubmit} method="POST">
          <h1>Cadastrar usuário</h1>
          <InputFlex
            id="name"
            label="Nome"
            type="text"
            name="name"
            onChange={e =>
              setDataToSent({ ...dataToSent, name: e.target.value })
            }
            required
          />

          <InputFlex
            id="surname"
            label="Sobrenome"
            type="text"
            name="surname"
            onChange={e =>
              setDataToSent({ ...dataToSent, surname: e.target.value })
            }
            required
          />

          <InputFlex
            id="email"
            label="Email"
            type="email"
            name="email"
            onChange={e =>
              setDataToSent({ ...dataToSent, email: e.target.value })
            }
          />

          <InputFlex
            id="password"
            label="Senha"
            type="password"
            name="password"
            onChange={e =>
              setDataToSent({ ...dataToSent, password: e.target.value })
            }
            required
          />

          <br />

          <Button variant="contained" color="primary" type="submit">
            SALVAR ALTERAÇÕES
          </Button>

          <Button
            className="button-back"
            variant="contained"
            color="primary"
            href="../users"
          >
            VOLTAR
          </Button>
        </Form>
      </div>
    </Container>
  );
}
