import React from 'react';
import PropTypes from 'prop-types';

import { Content, Wrapper } from './styles';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';

export default function DefaultLayout({ children }) {
  return (
    <Wrapper>
      <Header />
      <Content>
        <Sidebar />
        <div className="content-wrapper">{children}</div>
      </Content>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
